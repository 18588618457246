import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/posts-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Attention histoire non relue dont je suis très peu satisfait`}</strong></p>
    <p><em parentName="p">{`Thème du défi : Le racisme`}</em></p>
    <p>{`L’ambiance de la soirée était joyeuse. Nicolas organisait souvent des rassemblements chez lui pour parler de tout et de rien avec ses amis. L’atmosphère était bon enfant et Emmanuel se joignait souvent à la fête. Il connaissait Nicolas de son lieu de travail alors qu’il venait de déménager en ville, ces soirées étaient un bon moyen de rencontrer du monde.`}</p>
    <p>{`« Allez, je sens que l’ambiance retombe, je sais ce qu’il vous faut ! » lança Nicolas en se dirigeant vers la cuisine. Un silence court mais marqué s’installait dans le salon alors que l’hôte revenait avec une bouteille de rhum. La salle s’esclaffa de rire alors que Nicolas remplissait des verres à shots. Emmanuel rigolait à gorge déployée alors qu’il finissait une sa bière. Nicolas se dirigeait vers lui la bouteille à la main. Ivre, il fit tomber des fruits sur le chemin. Il saisit la banane au sol en ricanant et la tendit vers Emmanuel en lui disant « Tiens, ça c’est à toi, non ? »`}</p>
    <p>{`L’assemblée était hilare. Emmanuel mit quelques secondes à comprendre, il était d’origine africaine et il avait déjà entendu ce genre de blague. « Ça se fait vraiment pas ce genre de blague Nico ! » jeta Emilie entre deux pouffements de rire. « Oh c’est bon, c’est pour rire… » lui rétorqua Nicolas devant son public qui riait aux éclats. Alors que tout le monde le regardait, Emmanuel souriait de façon gênée tout d’abord puis laissa échapper quelques rires forcés alors qu’il avait le coeur froissé de par l’impression d’être exclu d’un monde auquel il croyait appartenir. L’espace d’une blague, son charisme, sa gentillesse, son éloquence, rien de tout ça n’avait d’importance, il était simplement réduit à sa couleur de peau. « Alors on se le fait ce shot de rhum ? » continua Nicolas, éméché, en finissant de remplir le dernier verre. La soirée continua dans le même climat joviale mais elle n’était plus pareille pour Emmanuel. Le coeur serré, il avait l’impression de s’être fait humilier, piétiner et trahir par une personne qu’il appréciait.`}</p>
    <p>{`Il rentra chez lui en trainant des pieds, les bras ballants. Seul dans son lit, il avait beau respirer, sa rage intérieure pouvait de moins en moins être contenue. « C’est pas drôle et c’est vraiment raciste ! » clama-t-il de façon véhémente à Nicolas. « Oh c’est bon, c’est pour rire… » La phrase résonnait dans sa tête. Il se retourna face à une foule hilare qui lui aboyait « Tu as vraiment pas d’humour Manu ! » La situation avait été vécue, elle était trop réelle. Emmanuel se releva de son lit d’un bond. Il se voyait rire de façon gênée pendant qu’une assemblée se moquait de lui. Humilié, il n’avait d’autre réponse que de feindre l’amusement pour ne pas devenir le rabat joie, celui qui ne connait pas l’auto dérision, le gardien du politiquement correct. Même s’il fallait laisser son honneur broyé, le besoin d’appartenir au monde était trop fort. Fallait-il laisser couler ? Oublier ? « Pas cette fois » se dit Emmanuel avec détermination. Il devait se faire respecter.`}</p>
    <p>{`La semaine d'après, une soirée similaire avait lieu. « Vous avez vu les infos ? » questionna Nicolas. « C’est dingue ce qui se passe aux Etats Unis ! George Floyd, son meurtre… Je comprends les manifestations » répondit Emilie interloquée. En prenant une gorgée de bière et s’enfonçant dans le canapé il continua d’un ton sérieux « C’est vraiment comme ça aux Etats Unis. Heureusement on a pas de problème de racisme en France. »`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      